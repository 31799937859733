import {
  SET_ASSIGNED_QUOTAS_LIST_DATA,
  OPEN_ASSIGNED_QUOTAS_FORM_MODAL,
  CLOSE_ASSIGNED_QUOTAS_FORM_MODAL
} from '../constants/action_constants';
import { sendRequest, completeRequest } from './request_state';
import { serverStatusError, newAlert } from './alert_actions';
import { listSanitizer, updateSanitizer, createSanitizer } from '../helpers/sanitizers/assignedQuotasSanitizer';
import RequestService from '../services/request_service';
import { history } from '../helpers/history';
import { stringifyQuery } from '../helpers/parseQuerystring';

const requestService = new RequestService('node');

export const fetchAssignedQuotasList = params => (
  async dispatch => {
    dispatch(sendRequest('AssignedQuotaList'));
    history.replace(`/quotas?${stringifyQuery(params)}`);
    try {
      const payload = await requestService.get(
        'contracts/assigned_quotas',
        listSanitizer(params)
      );
      dispatch(setAssignedQuotasListData(payload));
    } catch (errors) {
      dispatch(serverStatusError(errors));
      if (errors) {
        const e = await errors.json();
        e &&
          dispatch(
            newAlert('error', 'ERROR:', e.errors.base)
          );
      }
    } finally {
      dispatch(completeRequest('AssignedQuotaList'));
    }
  }
);

export const updateAssignedQuotas = params => (
  async dispatch => {
    dispatch(sendRequest('AssignedQuotaUpdate'));
    try {
      const response = await requestService.put(
        `contracts/assigned_quota/${params.fields.id}`,
        updateSanitizer(params.fields)
      );
      dispatch(
        newAlert('success', 'sucess', response.data.message)
      );
      dispatch(_closeAssignedQuotasFormModal());
      await fetchAssignedQuotasList(
        { page: params.page, perPage: params.perPage, stratum: params.stratum, geographicLocationId: params.geographicLocationId, contractTypeId: params.contractTypeId }
      )(dispatch);
    } catch (errors) {
      dispatch(serverStatusError(errors));
      if (errors) {
        const e = await errors.json();
        e &&
          dispatch(
            newAlert('error', 'ERROR:', e.errors.base)
          );
      }
    } finally {
      dispatch(completeRequest('AssignedQuotaUpdate'));
    }
  }
);

export const createAssignedQuota = params => (
  async dispatch => {
    dispatch(sendRequest('AssignedQuotaUpdate'));
    try {
      const response = await requestService.post(
        `contracts/assigned_quota`,
        createSanitizer(params.fields)
      );
      dispatch(
        newAlert('success', 'sucess', response.data.message)
      );
      dispatch(_closeAssignedQuotasFormModal());
      await fetchAssignedQuotasList(
        { page: params.page, perPage: params.perPage, stratum: params.stratum, geographicLocationId: params.geographicLocationId, contractTypeId: params.contractTypeId }
      )(dispatch);
    } catch (errors) {
      dispatch(serverStatusError(errors));
      if (errors) {
        const e = await errors.json();
        e && e.errors && e.errors.base &&
          dispatch(
            newAlert('error', 'ERROR:', e.errors.base)
          );
      }
    } finally {
      dispatch(completeRequest('AssignedQuotaUpdate'));
    }
  }
);

export const deleteAssignedQuota = params => (
  async dispatch => {
    dispatch(sendRequest('DeleteAssignedQuota'));
    try {
      const response = await requestService.delete(
        `contracts/assigned_quota/${params.fields.id}`
      );
      dispatch(
        newAlert('success', 'sucess', response.data.message)
      );
      dispatch(_closeAssignedQuotasFormModal());
      await fetchAssignedQuotasList(
        { page: params.page, perPage: params.perPage, stratum: params.stratum, geographicLocationId: params.geographicLocationId, contractTypeId: params.contractTypeId }
      )(dispatch);
    } catch (errors) {
      dispatch(serverStatusError(errors));
      if (errors) {
        const e = await errors.json();
        e && e.errors && e.errors.base &&
          dispatch(
            newAlert('error', 'ERROR:', e.errors.base)
          );
      }
    } finally {
      dispatch(completeRequest('DeleteAssignedQuota'));
    }
  }
);

export const openAssignedQuotasFormModal = () => (
  dispatch => {
    dispatch(_openAssignedQuotasFormModal());
  }
)

export const closeAssignedQuotasFormModal = () => (
  dispatch => {
    dispatch(_closeAssignedQuotasFormModal());
  }
)


const setAssignedQuotasListData = payload => {
  return {
    type: SET_ASSIGNED_QUOTAS_LIST_DATA,
    data: payload.data,
    meta: payload.meta
  };
};

const _openAssignedQuotasFormModal = () => {
  return {
    type: OPEN_ASSIGNED_QUOTAS_FORM_MODAL
  };
}

const _closeAssignedQuotasFormModal = () => {
  return {
    type: CLOSE_ASSIGNED_QUOTAS_FORM_MODAL
  };
}
