import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Formik, Field } from 'formik';
import moment from 'moment-timezone';
import _get from 'lodash/get';
import toSnakeCase from 'lodash/snakeCase';

import { history } from '../../../helpers/history';

import OutlinedTextField from '../../shared/Fields/OutlinedTextField';
import StepperButtons from '../../shared/StepperButtons';
import SimpleSelect from '../../shared/Selects/SimpleSelect';
import mapListToOptions from '../../../helpers/mapListToOptions';
import { doctypes } from '../../../constants/enums';
import OurDatePicker from '../../shared/CustomDatePicker';
import SegmentHeader from '../../shared/SegmentHeader';
import { validationSchema } from './PortalCosignerDataValidation';
import ActivityIndicator from '../../shared/ActivityIndicator';
import PrimaryButton from '../../shared/Buttons/PrimaryButton';
import CustomDialog from '../../shared/Modals/CustomDialog';

class PortalCosignerDataPage extends PureComponent {
  state = {
    isGnpSale: false,
    isSecureSale: false,
    validateSecureSaleConfirmationUse: false
  };
  currentStep = this.props.routes.indexOf(this.props.location.pathname);
  nextStep = this.currentStep + 1;

  componentDidMount() {
    const { gnpGasApplianceSaleEvaluation } = this.props;
    this.props.setCurrentStep(this.currentStep);
    if (
      (this.props.promissory &&
        !this.props.promissory.cosigner &&
        !this.props.transfer.requestMade) || (!this.props.requiresCosigner && this._isRemoteSale()) || this.props.isCommercialSale
    ) {
      this.props.autoSkipStep(this.props.location.pathname);
      this.skipCosigner();
    }
    if (
      gnpGasApplianceSaleEvaluation &&
      (gnpGasApplianceSaleEvaluation['gnp_sale']['make_gnp_sale'] &&
        gnpGasApplianceSaleEvaluation['gnp_sale']['supplier_is_gnp'] &&
        gnpGasApplianceSaleEvaluation['gnp_sale']['supplier_has_gnp_articles'])
    ) {
      this.setState({ isGnpSale: true });
    }
  }

  _isRemoteSale = () => {
    return (
      this.props.process === 'remoteDigitalSaleWithUUP' ||
      this.props.process === 'remoteDigitalSaleWithoutUUP'
    );
  };

  skipCosigner = () => {
    const { debtor } = this.props;
    this.props.resetCosignerData({
      debtorContractNumber: debtor.debtor_contract_number,
      debtorIdentification: debtor.debtor_identification,
      debtorIdentificationType: debtor.debtor_identification_type,
      debtorBirthdate: debtor.debtor_birthdate
    }, this.state.isSecureSale);
    this.props.setNextStep(this.props.process);
    history.push(this.props.routes[this.nextStep]);
  };

  isSecureSaleActive = () => {
    const saleType = toSnakeCase(window.location.pathname.split('/')[1]);
    return this.props.secureSaleActive[saleType];
  };

  onSubmit = values => {
    const {
      contract,
      cosignerDoctype,
      cosignerDocid,
      birthday,
      solidaryDebtorCausal
    } = values;

    const {
      debtor_contract_number,
      debtor_identification_type,
      debtor_identification,
      debtor_birthdate
    } = this.props.debtor;

    this.props.checkThirdStep(
      {
        debtor_contract_number,
        debtor_identification_type,
        debtor_identification,
        debtor_birthdate,
        cosigner_contract_number: contract ? Number(contract) : null,
        cosigner_identification_type: cosignerDoctype,
        cosigner_identification: cosignerDocid.toString(),
        cosigner_birthdate: birthday
          ? moment(birthday, 'YYYY/MM/DD').format('YYYY-MM-DD')
          : null,
        reason_solidary_debtor: solidaryDebtorCausal.toString()
      },
      this.props.routes[this.nextStep],
      this.props.process,
      this.state.isSecureSale
    );
  };

  docTypesWithoutIdentificationCard = () => {
    return doctypes.filter(docType => docType.name !== 'Tarjeta de Identidad');
  };

  requestSecureSaleToExperian = () => {
    const { debtor_identification_type, debtor_identification, debtor_contract_number } = this.props.debtor;

    this.props.checkSecureSaleExperian({
      identification: debtor_identification,
      typeOfIdentification: debtor_identification_type,
      contractId: debtor_contract_number
    }).then(response => {
      if (response.valid) {
        this.setState({ validateSecureSaleConfirmationUse: true });
      }
    });
  };

  // eslint-disable-next-line complexity
  render() {
    const {
      classes,
      serverErrors = {},
      requiresCosigner,
      holder,
      supplier,
      cosigner,
      promissory,
      transfer,
      debtorMaxAge,
      solidaryDebtorCauses = [],
      loading,
      canSellSecureSaleByZone,
      canCheckSecureSaleScoreExperian,
      isSecureSaleWithExperianActive
    } = this.props;

    const isAgeValid = Math.floor(moment().diff(this.props.debtor.debtor_birthdate, 'years', true)) <= debtorMaxAge;
    const canSellSecureSale = !holder && isAgeValid && supplier.canSellWithSecureSale && this.isSecureSaleActive() && canSellSecureSaleByZone;

    const { isGnpSale, isSecureSale } = this.state;
    const promissoryCosigner = _get(promissory, 'cosigner', false);

    if (promissory && !promissoryCosigner && !transfer.requestMade) {
      return <ActivityIndicator />;
    }

    return (
      <div className={classes.container}>
        <Paper className={classes.paper}>
          <Formik
            initialValues={{
              contract:
                transfer.contract ||
                _get(promissory, 'cosigner_contract') ||
                (requiresCosigner ? cosigner.contract || '' : ''),
              cosignerDoctype:
                transfer.identificationType ||
                _get(promissoryCosigner, 'type_of_identification') ||
                cosigner.identificationType ||
                'Cédula',
              cosignerDocid:
                transfer.identification ||
                _get(promissoryCosigner, 'identification') ||
                cosigner.identification ||
                '',
              birthday:
                transfer.birthdate ||
                (cosigner.birthdate
                  ? moment(cosigner.birthdate, 'YYYY-MM-DD').format(
                    'YYYY/MM/DD '
                  )
                  : _get(promissoryCosigner, 'identification')
                    ? moment(
                      promissoryCosigner.date_of_birth,
                      'YYYY-MM-DD'
                    ).format('YYYY/MM/DD ')
                    : null),
              solidaryDebtorCausal: requiresCosigner || promissoryCosigner ? 'REQUIRES COSIGNER' : cosigner.causal || '',
              requireContract: requiresCosigner && !promissoryCosigner
            }}
            validationSchema={validationSchema}
            onSubmit={this.onSubmit}
            onReset={(_, actions) => {
              actions.resetForm();
              this.setState({ isSecureSale: !isSecureSale });
            }}
            render={({
              values,
              touched,
              errors,
              handleSubmit,
              handleReset,
              handleBlur,
              setFieldValue
            }) => (
              <form className={classes.form}>
                <SegmentHeader className={classes.segmentHeader}>
                  {(transfer.contract && 'INFORMACIÓN CODEUDOR (TRASLADO)') ||
                    (requiresCosigner
                      ? 'INFORMACIÓN CODEUDOR'
                      : promissoryCosigner
                        ? 'INFORMACIÓN CODEUDOR (PAGARÉ ÚNICO)'
                        : 'CODEUDOR SOLIDARIO (OPCIONAL)')}
                </SegmentHeader>
                <Grid container spacing={24} alignItems="flex-start">
                  {!requiresCosigner &&
                    !promissoryCosigner &&
                    !transfer.contract && (
                      <Grid item sm={6} xs={12}>
                        <Field
                          name="solidaryDebtorCausal"
                          render={({ field }) => {
                            return (
                              <SimpleSelect
                                label="Causal:"
                                options={[
                                  {
                                    id: -1,
                                    key: -1,
                                    value: '',
                                    label: '--'
                                  },
                                  ...mapListToOptions(solidaryDebtorCauses)
                                ]}
                                onChange={e => {
                                  field.onChange(e);
                                  values.cosignerDocid = '';
                                  values.birthday = '';
                                }}
                                value={field.value}
                                name={field.name}
                                disabled={isGnpSale}
                              />
                            );
                          }}
                        />
                      </Grid>
                    )}
                  {(requiresCosigner ||
                    promissoryCosigner ||
                    transfer.contract) && (
                      <Grid item sm={6} xs={12}>
                        <Field
                          name="contract"
                          render={({ field }) => {
                            return (
                              <OutlinedTextField
                                disabled={
                                  !!promissoryCosigner || !!transfer.contract || !!isSecureSale
                                }
                                label="Numero de contrato:"
                                type={'number'}
                                {...field}
                                error={
                                  (touched.contract && !!errors.contract) ||
                                  serverErrors.contract
                                }
                                helperText={
                                  (touched.contract && errors.contract) ||
                                  serverErrors.contract
                                }
                              />
                            );
                          }}
                        />
                      </Grid>
                    )}
                  <Grid item sm={6} xs={12}>
                    <Field
                      name="cosignerDoctype"
                      render={({ field }) => {
                        return (
                          <SimpleSelect
                            disabled={
                              !values.solidaryDebtorCausal ||
                              !!promissoryCosigner ||
                              !!transfer.contract ||
                              !!isSecureSale
                            }
                            label="Tipo de identificacion del codeudor:"
                            options={mapListToOptions(
                              this.docTypesWithoutIdentificationCard(),
                              {},
                              true
                            )}
                            onChange={field.onChange}
                            value={field.value}
                            name={field.name}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Field
                      name="cosignerDocid"
                      render={({ field }) => {
                        return (
                          <OutlinedTextField
                            disabled={
                              !values.solidaryDebtorCausal ||
                              !!promissoryCosigner ||
                              !!transfer.contract ||
                              !!isSecureSale
                            }
                            label="Identificacion del codeudor:"
                            error={
                              !!values.solidaryDebtorCausal &&
                              ((touched.cosignerDocid &&
                                !!errors.cosignerDocid) ||
                                (serverErrors &&
                                  !!serverErrors.cosigner_identification))
                            }
                            helperText={
                              !!values.solidaryDebtorCausal &&
                              ((touched.cosignerDocid &&
                                errors.cosignerDocid) ||
                                (serverErrors &&
                                  serverErrors.cosigner_identification))
                            }
                            {...field}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <OurDatePicker
                      disabled={
                        !values.solidaryDebtorCausal ||
                        !!promissoryCosigner ||
                        !!transfer.contract ||
                        !!isSecureSale
                      }
                      name={'birthday'}
                      invalidLabel="Fecha inválida"
                      maxDateMessage="Fecha inválida"
                      minDateMessage="Fecha inválida"
                      invalidDateMessage={'Fecha inválida'}
                      label="Fecha nacimiento codeudor:"
                      value={values.birthday}
                      onChange={e => setFieldValue('birthday', e)}
                      onBlur={handleBlur}
                      onError={() => setFieldValue('birthday', null)}
                      error={
                        !!values.solidaryDebtorCausal &&
                        (!!serverErrors.cosigner_birthdate ||
                          (touched.birthday && !!errors.birthday))
                      }
                      helperText={
                        !!values.solidaryDebtorCausal &&
                        (serverErrors.cosigner_birthdate ||
                          (touched.birthday && errors.birthday))
                      }
                      fullWidth
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <div className={!canSellSecureSale ? classes.bottom : classes.bottomWithSecureSale}>
                  {canSellSecureSale && <Grid item xs={12} md={6}>
                    {isSecureSaleWithExperianActive && canCheckSecureSaleScoreExperian ? (
                      <PrimaryButton
                        variant="contained"
                        className={classes.button}
                        disabled={false}
                        onClick={this.requestSecureSaleToExperian}
                        loading={loading}
                      >
                        Solicitar fianza
                      </PrimaryButton>
                    ) : (
                      <FormControlLabel
                        className={classes.checkbox}
                        control={
                          <Checkbox
                            name="secureSale"
                            checked={isSecureSale}
                            onChange={handleReset}
                          />
                        }
                        label="Venta afianzada"
                      />)
                    }
                  </Grid>}
                  <StepperButtons
                    onSubmit={handleSubmit}
                    goBack={this.props.goBack}
                    skip={() => this.skipCosigner()}
                    allowOptional={
                      !transfer.contract &&
                      !requiresCosigner &&
                      !values.solidaryDebtorCausal ||
                      !!isSecureSale
                    }
                    allowNext={(!!values.solidaryDebtorCausal || transfer.contract) && !isSecureSale}
                  />
                </div>
              </form>
            )}
          />
          <CustomDialog
            open={
              this.props.isSecureSaleWithExperianActive &&
              this.state.validateSecureSaleConfirmationUse
            }
            onClose={() => this.setState({ validateSecureSaleConfirmationUse: false })}
            onConfirm={() => {
              this.setState({ validateSecureSaleConfirmationUse: false, isSecureSale: true }, () => {
                this.skipCosigner();
              });
            }}
            onConfirmSecond={() => this.setState({ validateSecureSaleConfirmationUse: false })}
            title="Notificación - Venta afianzada"
            secondButtonText="VOLVER"
            buttonText="ACEPTAR"
            disableBackdropClick={true}
          >
            <p>
              ¿Desea aplicar fianza?
            </p>
          </CustomDialog>
        </Paper>
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    display: 'flex'
  },
  paper: {
    flexGrow: 1
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  bottom: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end'
  },
  bottomWithSecureSale: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  checkbox: {
    marginTop: 3 * theme.spacing.unit,
    marginLeft: theme.spacing.unit / 2,
  },
  button: {
    alignSelf: 'flex-end',
    marginTop: 2 * theme.spacing.unit,
    paddingLeft: 8 * theme.spacing.unit,
    paddingRight: 8 * theme.spacing.unit
  },
  segmentHeader: {
    width: '100%',
    marginTop: 3 * theme.spacing.unit,
    marginBottom: 2 * theme.spacing.unit,
    '&:first-of-type': {
      marginTop: 0
    }
  }
});

PortalCosignerDataPage.propTypes = {
  classes: PropTypes.object.isRequired,
  newAlert: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default withStyles(styles)(PortalCosignerDataPage);
