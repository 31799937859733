export const columns = [
  {
    title: 'Nombre',
    name: 'name'
  },
  {
    title: 'Categoria Padre',
    name: 'parentName'
  },
  {
    title: 'Estado',
    name: 'categoryStatus'
  },
  {
    title: 'Tipo',
    name: 'contractType'
  }
];

export const mapData = data =>
  data.map(datum => {
    return {
      ...datum,
      name: datum.name || '--',
      categoryStatus: datum.active ? 'Activo' : 'Inactivo',
      parentName: datum.parent && datum.parent.name ? datum.parent.name : '--',
      parent_name: datum.parent && datum.parent.name ? datum.parent.name : '',
      parent_id: datum.parent && datum.parent.id ? datum.parent.id : null,
      parentId: datum.parent && datum.parent.id ? datum.parent.id : null,
      contractType: datum.contractTypeId == "1" ? 'Residencial' : 'Comercial',
    };
  });
