import _pickBy from 'lodash/pickBy';

export const articleFilterSanitizer = (filters, for_axios = false) => {
  const {
    name,
    category,
    brand,
    status,
    page,
    per_page,
    types,
    supplier
  } = filters;
  const types_matcher = for_axios
    ? 'q[article_types_code_in]'
    : 'q[article_types_code_in][]';
  const sanitizedFilters = {
    name_cont: name,
    category_name_cont: category,
    brand_name_cont: brand,
    [types_matcher]: types,
    suppliers_name_cont: supplier,
    page: page,
    per_page
  };
  if (status === 'activos') {
    sanitizedFilters.active_eq = true;
  }

  if (status === 'inactivos') {
    sanitizedFilters.active_eq = false;
  }
  return _pickBy(sanitizedFilters, filter => !!filter || filter === false);
};

export const articleFilterSanitizerNode = (filters) => {
  const {
    name,
    category,
    brand,
    page,
    perPage,
    supplierId,
    active,
    isCommercialSale
  } = filters;

  const sanitizedFilters = {
    name,
    category,
    brand,
    supplierId,
    active,
    page,
    perPage,
    isCommercialSale
  };

  return _pickBy(sanitizedFilters, filter => !!filter || filter === false);
};

export const articleSaveSanitizer = values => {
  const {
    name,
    category_id: categoryId,
    iva,
    description,
    concept_id: conceptId,
    active,
    brand_id: brandId,
    suppliers,
    article_types,
    maxItems,
    grace_period: gracePeriod
  } = values;

  const sanitizedFilters = {
    name,
    categoryId,
    iva: Number(iva) || 0,
    description,
    conceptId,
    active,
    brandId,
    gracePeriod,
    supplierIds: suppliers.map(s => s.value),
    articleTypesIds: article_types.map(at => Number(at.value))
  };

  const params = _pickBy(
    sanitizedFilters,
    filter => !!filter || filter === false
  );

  return {
    ...params,
    maxItems: maxItems && Number(maxItems) > 0 ? Number(maxItems) : null,
    iva: params.iva || 0
  };
};
