/* eslint-disable complexity */
import { CardActions } from '@material-ui/core';
import {
  PORTAL_FINANCING_CLEAR,
  PORTAL_FINANCING_PERSIST,
  PORTAL_FINANCING_VALIDATED_INFORMATION,
  STEP_LOADING,
  PORTAL_LOADING,
  STEP_SUCCESS,
  STEP_ERROR,
  TOGGLE_BASE_MODAL,
  RESET_BASE_MODAL,
  TOGGLE_ID_MODAL,
  DC_DEBTOR_LOADING,
  DC_COSIGNER_LOADING,
  DC_DEBTOR_ERROR,
  DC_COSIGNER_ERROR,
  RESET_COSIGNER_DATA,
  HANDLE_ARTICLES,
  STEP_NOT_LOADING,
  CHANGE_AV_QUOTA,
  EXTRA_QUOTAS_SET,
  RESET_ADD_QUOTAS,
  RESET_MOVE_QUOTA,
  RESET_PROMISSORY_DATA,
  TOGGLE_SCORING_MODAL,
  SET_SCORING_QUOTA,
  RESET_SCORING,
  RESTORE_AV_QUOTA,
  SET_APPLY_SCORING,
  SET_QUESTIONS_INVOICE,
  INVOICES_VALID,
  SET_DEBTOR_IDENTIFICATION,
  SET_DEBTOR_DOCUMENT_CAPTURE,
  RESET_DEBTOR_IS_ON_FACIAL_DB,
  SET_DEBTOR_BIRTHDATE,
  SET_DEBTOR_MOBILE,
  SET_INVOICE_VAL_STEP_SKIPPED,
  RESET_IDENTITY_OTP_CODE,
  SET_MEGA_QUOTA,
  RESET_MEGA_QUOTA,
  OTP_SEND_TRANSACTION_ID,
  OTP_RE_SEND_TRANSACTION_ID
} from '../constants/action_constants';
import { OTP_V2_VALIDATION_RESPONSE, RESET_OTP_V2_INFO_RESPONSE } from '../constants/otp_validation_constants';

const initialState = {
  //STEP LOADING AND ERRORS
  stepIsLoading: false,
  portalIsLoading: false,
  errors: {},
  baseErrorOpen: false,
  baseErrorText: '',
  baseErrorTitle: '',
  baseErrorRoute: '',
  //START - DEBTOR
  interest_rate: '',
  obligatory_insurance_rate: '',
  voluntary_insurance_value: '',
  voluntary_insurance_value_grace_period: '',
  motorbike_voluntary_insurance_grace_period_active: '',
  motorbike_voluntary_insurance_grace_period_value: '',
  validate_sale_invoices: null,
  validate_promissory_invoices: null,
  validate_with_one_invoice: false,
  valid: false,
  require_cosigner: false,
  unique_promissory_id: '',
  holder: false,
  isSecureSale: false,
  gnp_gas_appliance_evaluation: null,
  is_commercial_sale: false,
  is_urban_contract: false,
  urban_and_rural_interest_rate: {},
  debtor_contract_number: '',
  debtor_identification_type: '',
  debtor_identification: '',
  debtor_birthdate: null,
  lower_interest_rate: '',
  excluded_lines_from_general_interest_rate: [],
  //QUOTA
  address: '',
  assigned_quota: -1,
  available_quota: -1,
  available_quota_backup: -1,
  breached_policies: [],
  category: '',
  department: '',
  identification: '',
  last_name: '',
  locality: '',
  megaQuotaInfo: [],
  useMegaQuota: false,
  hasPets: false,
  name: '',
  network_balance: 0,
  phone: '',
  promissory: '',
  segment: '',
  stratum: 0,
  type_of_identification: '',
  used_quota: -1,
  usedQuotaByClient: 0,
  transferContract: '',
  transferIdentificationType: '',
  transferIdentification: '',
  transferBirthdate: null,
  addQuotasData: {
    requestMade: false,
    secondContractQuota: '--',
    thirdContractQuota: '--'
  },
  moveQuotaData: {
    requestMade: false,
    clientName: '',
    availableQuota: '--',
    contract: '',
    identificationType: '',
    identification: '',
    birthdate: null
  },
  ongoingTransfer: false,
  //COSIGNER
  cosigner_contract_number: '',
  cosigner_identification_type: '',
  cosigner_identification: '',
  cosigner_birthdate: null,
  reason_solidary_debtor: '',
  cosignerContractData: {},
  //IDENTIFICATION CHECK
  idModal: false,
  id_number: '',
  id_department: '',
  id_locality: '',
  id_name: '',
  id_first_last_name: '',
  id_second_last_name: '',
  id_gender: '',
  id_expedition_date: '',
  id_expiration_date: '',
  id_status: '',
  id_country: '',
  dc_cosigner_number: '',
  dc_cosigner_name: '',
  dc_cosigner_first_last_name: '',
  dc_cosigner_second_last_name: '',
  dc_cosigner_locality: '',
  dc_cosigner_department: '',
  dc_cosigner_gender: '',
  dc_cosigner_expedition_date: '',
  dc_cosigner_expiration_date: '',
  dc_cosigner_status: '',
  dc_country: '',
  dcDebtorLoading: false,
  dcCosignerLoading: false,
  dcDebtorErrors: {},
  dcCosignerErrors: {},
  dcDebtorSuccess: false,
  dcCosignerSuccess: false,
  dcDebtorLastName: '',
  dcCosignerLastName: '',
  // IDENTITY OTP
  identityOTPRequiresQuestions: false,
  identityOTPtimestamp: '',
  identityOTPCode: '',
  identityOTPEmailSent: false,
  identityOTPMobileSent: false,
  identityOTPTransactionId: '',
  identityOTPRegValidation: '',
  identityOTPName: '',
  identityOTPLastName: '',
  identityOTPEmail: '',
  identityOTPMobile: '',
  usingCrosscoreParams: true,
  questionsFailed: false,
  //SCORING
  useScoring: false,
  scoringModal: false,
  scoringQuota: 0,
  applyScoring: false,
  //INVOICES
  invoices_valid: false,
  invoice1: {
    id: null,
    date: null,
    answer: null
  },
  invoice2: {
    id: null,
    date: null,
    answer: null
  },
  questionsInvoice: [{}],
  invoiceValidationSkipped: false,
  //SUPPLIER
  point_of_sale: '',
  sale_channel: '',
  clerk: '',
  saleDate: '',
  deliverToPointOfSale: false,
  posCanUseCrosscore: false,
  articles: [],
  sellers: [],
  extraQuotas: [],
  //ARTICLES
  selectedArticles: null,
  insurances: null,
  articleInstallments: '',
  downPayment: 0,
  observations: '',
  validatedSaleDetails: {},
  professions: [],
  //PROMISSORY
  saleNumber: '',
  saleCompleted: false,
  //DIGITAL SALE
  debtor_is_on_facial_db: false,
  is_front_captured_debtor_document: false,
  is_back_captured_debtor_document: false,
  digital_sale_with_pui: false,
  voluntaryInsurance: {},
  saleCacheResponse: {},
  transaccionId: null,
  base64: null,
  external_process_id: null,
  otpValidatorDebtor: null,
  otpValidatorCosigner: null,
  voucher_body: null,
};

export function portalFinancing(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_BASE_MODAL:
      return {
        ...state,
        baseErrorOpen: action.open,
        baseErrorText: action.text,
        baseErrorTitle: action.title,
        baseErrorRoute: action.route
      };
    case RESET_BASE_MODAL:
      return {
        ...state,
        baseErrorOpen: false,
        baseErrorText: '',
        baseErrorTitle: '',
        baseErrorRoute: ''
      };
    case PORTAL_FINANCING_CLEAR:
      return initialState;
    case PORTAL_FINANCING_PERSIST:
      return action.data;
    case PORTAL_FINANCING_VALIDATED_INFORMATION:
      return {
        ...state,
        ...action.data
      };
    case STEP_LOADING:
      return {
        ...state,
        stepIsLoading: true
      };
    case STEP_NOT_LOADING:
      return {
        ...state,
        stepIsLoading: false
      };
    case DC_DEBTOR_LOADING:
      return {
        ...state,
        dcDebtorLoading: action.status
      };
    case DC_COSIGNER_LOADING:
      return {
        ...state,
        dcCosignerLoading: action.status
      };
    case TOGGLE_ID_MODAL:
      const dcDebtorSuccess = !action.active ? false : state.dcDebtorSuccess;
      const dcCosignerSuccess = !action.active
        ? false
        : state.dcCosignerSuccess;
      const dcDebtorErrors = !action.active ? {} : state.dcDebtorErrors;
      const dcCosignerErrors = !action.active ? {} : state.dcCosignerErrors;
      const dcDebtorLoading = !action.active ? false : state.dcDebtorLoading;
      const dcCosignerLoading = !action.active
        ? false
        : state.dcCosignerLoading;
      return {
        ...state,
        idModal: action.active,
        dcDebtorSuccess,
        dcCosignerSuccess,
        dcDebtorErrors,
        dcCosignerErrors,
        dcDebtorLoading,
        dcCosignerLoading
      };
    case TOGGLE_SCORING_MODAL:
      return {
        ...state,
        scoringModal: action.active
      };
    case SET_DEBTOR_IDENTIFICATION:
      return {
        ...state,
        debtor_identification_type: action.identificationType,
        debtor_identification: action.identification,
        debtor_is_on_facial_db: action.debtorIsOnFacialDb,
        digital_sale_with_pui: action.digital_sale_with_pui === 1 ? true : false
      };
    case SET_DEBTOR_DOCUMENT_CAPTURE:
      return {
        ...state,
        is_front_captured_debtor_document: action.isFrontCaptured,
        is_back_captured_debtor_document: action.isBackCaptured
      };
    case RESET_DEBTOR_IS_ON_FACIAL_DB:
      return {
        ...state,
        debtor_is_on_facial_db: false
      };
    case SET_DEBTOR_BIRTHDATE:
      return {
        ...state,
        debtor_birthdate: action.debtorBirthdate
      };
    case SET_DEBTOR_MOBILE:
      return {
        ...state,
        debtorMobile: action.debtorMobile
      };
    case PORTAL_LOADING:
      return {
        ...state,
        portalIsLoading: action.isLoading
      };
    case STEP_SUCCESS:
      return {
        ...state,
        ...action.data,
        stepIsLoading: false,
        errors: {}
      };
    case STEP_ERROR:
      return {
        ...state,
        stepIsLoading: false,
        errors: action.errors
      };
    case DC_DEBTOR_ERROR:
      return {
        ...state,
        dcDebtorLoading: false,
        dcDebtorErrors: action.errors,
        dcDebtorSuccess: false
      };
    case DC_COSIGNER_ERROR:
      return {
        ...state,
        dcCosignerLoading: false,
        dcCosignerErrors: action.errors,
        dcCosignerSuccess: false
      };
    case RESET_COSIGNER_DATA:
      let newState = {
        ...state,
        cosigner_contract_number: '',
        cosigner_identification_type: '',
        cosigner_identification: '',
        cosigner_birthdate: '',
        reason_solidary_debtor: '',
        dc_cosigner_status: '',
        dcDebtorSuccess: false,
        dcCosignerSuccess: false,
        cosignerContractData: {},
        ani_cosigner_response: null
      };

      if (state.tempPromissory) {
        newState["tempPromissory"] = {
          ...state.tempPromissory,
          cosigner: null,
          cosigner_address: { location: "", department: "" }
        };
      }

      return newState;
    case RESET_PROMISSORY_DATA:
      return {
        ...state,
        promissory: null
      };
    case RESET_ADD_QUOTAS:
      return {
        ...state,
        addQuotasData: initialState.addQuotasData,
        ongoingTransfer: false,
        cosigner_contract_number: '',
        cosigner_identification_type: '',
        cosigner_identification: '',
        cosigner_birthdate: null
      };
    case RESET_MOVE_QUOTA:
      return {
        ...state,
        moveQuotaData: initialState.moveQuotaData,
        ongoingTransfer: false,
        cosigner_contract_number: '',
        cosigner_identification_type: '',
        cosigner_identification: '',
        cosigner_birthdate: null
      };
    case HANDLE_ARTICLES:
      return {
        ...state,
        ...action.data
      };
    case SET_QUESTIONS_INVOICE:
      return {
        ...state,
        questionsInvoice: action.data
      };
    case INVOICES_VALID:
      return {
        ...state,
        invoices_valid: action.data
      };
    case CHANGE_AV_QUOTA:
      return {
        ...state,
        available_quota:
          Number.parseFloat(state.available_quota) +
          Number.parseFloat(action.additionalQuota),
        ongoingTransfer: true
      };
    case EXTRA_QUOTAS_SET:
      return {
        ...state,
        extraQuotas: action.data
      };
    case SET_SCORING_QUOTA:
      return {
        ...state,
        available_quota: Number.parseFloat(action.scoringQuota),
        useScoring: true
      };
    case RESET_SCORING:
      return {
        ...state,
        useScoring: false,
        scoringModal: false,
        applyScoring: false,
        scoringQuota: 0
      };
    case SET_MEGA_QUOTA:
      return {
        ...state,
        available_quota: Number.parseFloat(action.megaQuota),
        useMegaQuota: true
      };
    case RESET_MEGA_QUOTA:
      return {
        ...state,
        useMegaQuota: false,
        megaQuotaInfo: [],
        available_quota: Number.parseFloat(state.available_quota_backup)
      };
    case RESTORE_AV_QUOTA:
      return {
        ...state,
        available_quota: Number.parseFloat(state.available_quota_backup)
      };
    case SET_APPLY_SCORING:
      return {
        ...state,
        applyScoring: action.data
      };
    case SET_INVOICE_VAL_STEP_SKIPPED:
      return {
        ...state,
        invoiceValidationSkipped: action.invoiceValidationSkipped
      };
    case RESET_IDENTITY_OTP_CODE:
      return {
        ...state,
        identityOTPCode: action.data
      };
    case OTP_SEND_TRANSACTION_ID:
      return {
        ...state,
        transaccionId: action.data.transaccionId,
      };
    case OTP_RE_SEND_TRANSACTION_ID:
      return {
        ...state,
        succesfullyReSent: action.data.succesfullyReSent
      };
    default:
      return state;
  }
}
